import { useCaseService } from "~/src/services/CaseService";
import { CaseStatus } from "~/src/models/Case/Case.model";
import { defineNuxtRouteMiddleware, navigateTo } from "#imports";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { useGetCaseQuery } = useCaseService();
  const { data: result } = await useGetCaseQuery(String(to.params.caseId));

  const status = result.value?.result.caseStatus?.status;

  const goToHistory =
    status === CaseStatus.InProgress || status === CaseStatus.Closed;

  if (
    goToHistory &&
    to.path !== `/cases/${to.params.caseId}/history` &&
    from.name === "cases"
  )
    return navigateTo(`/cases/${to.params.caseId}/history`);
  else return;
});
